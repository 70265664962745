<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>行业案例管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="24">
        <el-select @change="selectChange" v-model="queryInfo.type" placeholder="请选择">
          <el-option
            v-for="item in typeValue"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="industryList"
      v-bind="otherProps"
      :listCount="industryTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #typeSlot="scope">{{ scope.row.type | industryTypeFormat }}</template>
      <template #coverSlot="scope">
        <el-image slot="error" :src="scope.row.cover_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #operation="scope">
        <span v-if="solution_id" @click="addbindSolution(scope.row)" style="margin-right: 10px;color: rgb(73, 73, 214);cursor: pointer;">关联</span>
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-edit-industry-dialog :params="addEditInfo" @close="addEditDialogClose"></add-edit-industry-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/industryConfig.js'
import { deleteIndustry } from '@/api/industry/industry'
import addEditIndustryDialog from './cnps/add-edit-industry-dialog.vue'
import { addSolutiontIndustryBind } from '@/api/solution/solution'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      typeValue: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '教育',
          value: 1
        },
        {
          label: '金融',
          value: 2
        },
        {
          label: '制造',
          value: 3
        },
        {
          label: '互联网',
          value: 4
        },
        {
          label: '集团',
          value: 5
        },
      ],
      queryInfo: {
        cur: 1,
        size: 20,
        type: 0,
      },
      addEditInfo: {
        show: false,
        data: null,
      },
      solution_id: null,
    }
  },
  components: {
    TableUi,
    addEditIndustryDialog,
  },
  mounted() {
    this.solution_id = this.$route.query.solution_id
    this.getDataList()
  },
  computed: {
    ...mapState('industry', ['industryList', 'industryTotal'])
  },
  methods: {
    ...mapActions('industry', ['getIndustryList']),
    getDataList () {
      this.getIndustryList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    selectChange() {
      this.queryInfo.cur = 1
      this.getDataList()
    },
    // 增加
    addClick() {
      this.addEditInfo.show = true
    },
    // 编辑
    async editClick(row) {
      this.addEditInfo.data = {
        id: row.id,
        type: row.type,
        title_cn: row.title_cn,
        title_us: row.title_us,
        subhead_cn: row.subhead_cn || '',
        subhead_us: row.subhead_us || '',
        cover_path: row.cover_path,
        introduce_cn: row.introduce_cn || '',
        introduce_us: row.introduce_us || '',
        architecture_cn: row.architecture_cn || '',
        architecture_us: row.architecture_us || '',
      }
      this.addEditInfo.show = true
    },
    // 关闭窗口
    addEditDialogClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.data = null;
      this.getDataList()
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteIndustry(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    },
    // 关联到解决方案
    async addbindSolution (row) {
      const result = await addSolutiontIndustryBind({
        industry_id: row.id,
        solution_id: this.solution_id,
      })
      if (result.success) {
        this.$message.success('成功')
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
  .el-button {
    margin-left: 10px;
  }
</style>